(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header = fn.find(".wrp_header");
        fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"></div><div class="elm_content"></div></nav>');

        let layout_nav = doc.find("#layout_nav"),
            logo = header.find(".elm_logo").clone(),
            social = header.find(".elm_header_social").clone(),
            nav = header.find(".elm_nav").clone();

        layout_nav.find(".elm_head").append(logo);
        layout_nav.find(".elm_content").append(nav);
        layout_nav.find(".elm_content").append(social);

        $.getScript(cdnjs.touchswipe).done(function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.getScript(cdnjs.headroom).done(function() {
            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance: {
                    up: 5,
                    down: 0
                },
                classes: {
                    "initial": "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top": "headroom--top",
                    "notTop": "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);
