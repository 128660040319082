(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    
    // $('#snippet-contactform').antiSpam();
    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    // ALAX LINKS
    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });
    
    // ALAX LINKS
    doc.on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        let $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1},
            dataType: "json",
            success: function (payload) {
                $('[data-changegallery]').removeClass('mod--active');
                $el.addClass('mod--active');
                fn_ajaxHandler(payload);
                sr.sync();
                
                let $fn = $(".comp_gallery");

                if ($fn.length) {
                    $fn.find("[data-lightgallery-block]").lightGallery({
                        thumbnail: true,
                        selector: ".part_item_gallery",
                        exThumbImage: 'data-exthumbimage',
                        fullScreen: true,
                        zoom: true,
                        actualSize: false,
                        hash: false,
                        download: true,
                        autoplay: false,
                        autoplayControls: false
                    });
                }
            }
        });
    });
})(jQuery);